const muiStyles = {
    buttonStyles: {
        textTransform: "none",
        backgroundColor: "#1976D2",
        color: "#FFFFFF",
        boxShadow: "none",
        padding: '17px 20px 17px 16px',
        borderRadius: '8px',
        width: '100%',
        maxWidth: '300px',
        '&:hover': {
            backgroundColor: '#1976D2',
        },
        fontWeight: 400,
        fontFamily: 'Roboto',
    },
    circleStyles: {
        color: "#1976D2"
    }
}

export default muiStyles;