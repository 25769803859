import "@tensorflow/tfjs-core";
import "@tensorflow/tfjs-converter";
import "@tensorflow/tfjs-backend-webgl";
import * as faceLandmarksDetection from "@tensorflow-models/face-landmarks-detection";
import { MediaPipeFaceMeshMediaPipeModelConfig } from "@tensorflow-models/face-landmarks-detection";

export default class FaceMeshDetector {
    detectorConfig: MediaPipeFaceMeshMediaPipeModelConfig;
    model: faceLandmarksDetection.SupportedModels.MediaPipeFaceMesh;
    detector: faceLandmarksDetection.FaceLandmarksDetector | null;
  
    constructor(){
      this.model = faceLandmarksDetection.SupportedModels.MediaPipeFaceMesh;
      this.detectorConfig = {
        runtime: 'mediapipe',
        refineLandmarks: true,
        solutionPath: 'https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh',
       }
      this.detector = null;
    }
  
    private async getDetector(){
      const detector = await faceLandmarksDetection.createDetector(
        this.model, 
        this.detectorConfig as faceLandmarksDetection.MediaPipeFaceMeshMediaPipeModelConfig
      );
      return detector;
    }
  
    async loadDetector(){
      this.detector = await this.getDetector()
    }
  
  }