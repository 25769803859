
import { Button, Typography, CircularProgress } from "@mui/material";
import React, { useEffect, useRef } from "react";
import Webcam from "react-webcam";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { callsExternal } from "../../services/callsExternal";
import './IdDocFace.scss';
import muiStyles from '../../muiCustomStyles';
const callsexternal = new callsExternal();


interface IdDocFaceProps {
    idnumber: string;
    setView: (view: string) => void;
    setHoldingid: (holdingid: string) => void;
    instruction: string;
    facingMode: string | { [key: string]: any };
    payload: { [key: string]: any };
    setResult: (res: { [key: string]: any }) => void;
    result: { [key: string]: any };
    selfie: string;
    requestid: string | null;
    succeeded: number;
}

const IdDocFace: React.FC<IdDocFaceProps> = ({ idnumber, setView, setHoldingid, instruction, facingMode, payload, setResult, result, selfie, requestid, succeeded }: IdDocFaceProps) => {
    const canvas = useRef<HTMLCanvasElement>(null);
    const webcam = useRef<Webcam>(null);
    const [instructionstr, setInstruction] = React.useState<string>(instruction);
    const [size, setWindowSize] = React.useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const [photo, setPhoto] = React.useState<string>("");

    const videoConstraints = {
        facingMode: facingMode,
    };

    muiStyles.buttonStyles.backgroundColor = payload.request.btn_color;
    muiStyles.buttonStyles['&:hover'].backgroundColor = payload.request.btn_color;
    muiStyles.buttonStyles.color = payload.request.btn_text_color;
    muiStyles.circleStyles.color = payload.request.btn_color;

    //Verify once photo is taken
    useEffect(() => {
        const doComparison = async () => {
            if (photo != "") {
                let i = photo.indexOf(",");
                let holdingid = encodeURIComponent(photo.substring(i + 1));
                try {
                    let compareresult: { [key: string]: any } = await callsexternal.documentCompareFunction(idnumber, selfie, requestid, holdingid, null,payload.apikey, payload.hash);
                    console.log("Got compareresult:" + JSON.stringify(compareresult));
                    if (compareresult.selfietoholding.status == 'SUCCEEDED') {
                        let confidence = [];
                        if (compareresult.selfietoholding.faces.length > 0) {
                            for (let f = 0; f < compareresult.selfietoholding.faces.length; f++) {
                                confidence.push(compareresult.selfietoholding.faces[f].confidence);
                            }
                        }
                        result.holdingiddoc = {
                            status: compareresult.selfietoholding.status,
                            statusMessage: compareresult.selfietoholding.statusMessage,
                            idnumberscore: compareresult.selfietoholding.idnumberscore,
                            namescore: compareresult.selfietoholding.namescore,
                            surnamescore: compareresult.selfietoholding.surnamescore,
                            confidence: confidence
                        }
                        setResult(result);
                        setTimeout(async () => {
                            if (payload.request.min_succeed == 3 && succeeded == 2) { // We need 3 successes and we have them 
                                if (payload.request.score_url != undefined && result != undefined) {
                                    await callsexternal.postToScoreUrl(result, payload.request.score_url,payload.hash);
                                }
                                window.location.replace(payload.request.return_url_success);
                            } else {
                                if (payload.request.score_url != undefined && result != undefined) {
                                    await callsexternal.postToScoreUrl(result, payload.request.score_url,payload.hash);
                                }
                                window.location.replace(payload.request.return_url_fail);
                            }
                        }, 3000);

                    } else {
                        result.holdingiddoc = {
                            status: compareresult.selfietoholding.status,
                            statusMessage: compareresult.selfietoholding.statusMessage
                        }
                        setResult(result);

                        if (payload.request.score_url != undefined && result != undefined) {
                            await callsexternal.postToScoreUrl(result, payload.request.score_url,payload.hash);
                        }
                        window.location.replace(payload.request.return_url_fail);
                    }
                } catch (err) {
                    console.log("got post error:" + err);
                    setResult({ error: err });
                }
            }
        }
        doComparison();
    }, [photo]);

    const takePhoto = () => {
        if (webcam != null && webcam.current != null) {
            var currentWebcam = webcam.current;
            let imageSrc = currentWebcam.getScreenshot();
            if (imageSrc != null) {
                setInstruction("Thank you for the photo");
                let i = imageSrc.indexOf(",");
                let sourceImage = encodeURIComponent(imageSrc.substring(i + 1));
                setHoldingid(sourceImage);
                setPhoto(imageSrc);
            }
        }
    };

    if (photo === "") {
        return (
            <div className="SelfieApp-carema-view">
            <div className="SelfieApp-carema-view__instruction">
                {instructionstr}
            </div>
            <div className="SelfieApp-carema-view__wrapper">
                <Webcam
                    audio={false}
                    ref={webcam}
                    videoConstraints={videoConstraints}
                    screenshotFormat='image/jpeg'
                    mirrored={false}
                    style={{
                        margin: "auto",
                        textAlign: "center",
                        top: 0,
                        left: 0,
                        right: 0
                    }}
                />
                
            </div>
            <footer>
                <Button
                size="large"
                variant="contained"
                sx={muiStyles.buttonStyles}
                onClick={() => takePhoto()}>
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{margin: '0 10px 0 0'}}>
                <path d="M17.7704 5.625H20.5C20.8647 5.625 21.2144 5.76987 21.4723 6.02773C21.7301 6.28559 21.875 6.63533 21.875 7V19C21.875 19.3647 21.7301 19.7144 21.4723 19.9723C21.2144 20.2301 20.8647 20.375 20.5 20.375H4.5C4.13533 20.375 3.78559 20.2301 3.52773 19.9723C3.26987 19.7144 3.125 19.3647 3.125 19V7C3.125 6.63533 3.26987 6.28559 3.52773 6.02773C3.78559 5.76987 4.13533 5.625 4.5 5.625H7.22956C7.68557 5.625 8.1206 5.4334 8.42843 5.09697L9.66378 3.74685C9.73482 3.66922 9.83521 3.625 9.94044 3.625H15.0596C15.1648 3.625 15.2652 3.66922 15.3362 3.74685L16.5716 5.09697L17.0132 4.6929L16.5716 5.09697C16.8794 5.4334 17.3144 5.625 17.7704 5.625Z" stroke={payload.request.btn_text_color} strokeWidth="1.25" strokeLinejoin="round"/>
                <path d="M16.375 12.5C16.375 14.6401 14.6401 16.375 12.5 16.375C10.3599 16.375 8.625 14.6401 8.625 12.5C8.625 10.3599 10.3599 8.625 12.5 8.625C14.6401 8.625 16.375 10.3599 16.375 12.5Z" stroke={payload.request.btn_text_color} strokeWidth="1.25"/>
                </svg>
                Take Photo</Button>
            </footer>
        </div>
        );
    } else {
        return (
            <div className="SelfieApp-carema-view">
                <div className="SelfieApp-carema-view__instruction">
                    <div className="instruction">{instructionstr}</div>
                    {/* <div className="result">{JSON.stringify(result)}</div> */}
                </div>
                <div className="SelfieApp-carema-view__wrapper">
                    <img className="idimg" src={photo} alt="idimage"/>
                </div>
                <footer>
                    <CircularProgress sx={muiStyles.circleStyles} />
                </footer>
            </div>
        );
    }
}
export default IdDocFace;
